import { MailBotsClient } from "@mailbots/mailbots-sdk";
import cookie from "js-cookie";
import MailBotsAdminBrowser from "./mailbotsAdminBrowser";
import moment from "moment-timezone";
import _ from "lodash";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { message as antMessage } from "antd";
import queryString from "query-string";
import { logger } from "./Logger";
import {
  getReferralHash,
  hasEmployees,
  isEmployee,
  isTeamOwner
} from "../Billing/billingHelpers";
import { FormatGenerator } from "./FormatGenerator";

// set sensitive cookies to be https-only
const secureCookies =
  process.env.REACT_APP_USE_SECURE_COOKIES === "true" ? true : false;

/**
 * Axios cache uses the unique request URLs as unique identifiers for a cache value. When a POST is sent
 * to that URL it invalidates a cache. V3 has a method to override, but not 2. That means that the people/search
 * endpoint cannot yet be cached.
 * Only filter endpoints iwth /tasks /self and /mailbots right now (/people will hopefully work eventually)
 */
export const axiosCache = setupCache({
  maxAge: 5 * 60 * 1000, // 5min
  exclude: {
    filter: param => {
      // return true; // turn off caching with this line instead of the following
      // cache only these endpoints and exclude everything else
      const shouldCacheUrlWildcards = [
        "api/v1/users/self",
        "api/v1/people",
        "api/v1/mailbots"
      ];
      const shouldNotCacheUrlWildcards = ["logs"];

      const shouldCache = shouldCacheUrlWildcards.some(resource =>
        param.url.includes(resource)
      );

      const excludeOverride = shouldNotCacheUrlWildcards.some(resource =>
        param.url.includes(resource)
      );

      if (excludeOverride) return true; // true means exclude from cache (for axios)
      if (shouldCache) return false; // false means do cache (for axios)
      return true; // don't cache anything else in the cache
    }
  },
  // Cache Invalidation Conditions:
  // * Any non-GET request to a resource invalidates every cache with that resource in the url /api/v1/(.*)/
  // * invalidates every task URL without a number, and any URL with that taskid
  // * Any request other than GET to any URL invalidates that URL
  invalidate: async (cfg, req) => {
    const method = req.method.toLowerCase();
    const resourceMatches = req.url.match(/.*api\/v1\/(.*)?\//);
    const resource = Array.isArray(resourceMatches) ? resourceMatches[1] : null;
    if (method !== "get") {
      // aggressively invalidate any variations of a resource URL after get/put/delete, etc
      await invalidateCacheVariant(cfg.store, resource);
    }
  }
});

/**
 * Some task urls pass ?verbose onto to the end to fetch the rendered tasks. Both of these variants
 * would need to be invalidated when a task has been updated.
 * @param {*} cacheStore
 * @param {*} partialUrl
 */
function invalidateCacheVariant(cacheStore, partialUrl) {
  for (let url in cacheStore.store) {
    if (url.includes(partialUrl)) {
      cacheStore.removeItem(url);
    }
  }
}

const axiosClient = axios.create({
  adapter: axiosCache.adapter
});

function getMailBotsClient() {
  var mailbotsClient = new MailBotsClient({
    clientId: "",
    apiHost: process.env.REACT_APP_MAILBOTS_API_BASE,
    axiosClient
  });

  mailbotsClient.setAccessToken(getAccessToken());
  return mailbotsClient;
}

// TODO: Remove these from repo
function getmailbotsAdminBrowser() {
  const mailbotsAdminBrowser = new MailBotsAdminBrowser({
    clientId: "",
    apiHost: process.env.REACT_APP_MAILBOTS_API_BASE,
    axiosClient
  });

  mailbotsAdminBrowser.setAccessToken(getAccessToken());
  return mailbotsAdminBrowser;
}

export const mailbotsAdminBrowser = getmailbotsAdminBrowser();
export const mailbotsClient = getMailBotsClient();

/**
 *
 *
 * Get some friendly date options! This replaces userDate and conversationalDate
 * @param {object} params
 * @param {number} params.unixTime - Unix (not JS) timestamp
 * @param {string} [params.userTimezone] - User timezone setting (defaults to GMT)
 * @param {string} [params.format] - Moment (js) date format
 *
 * @typedef friendlyDateReturnObj
 * @type {Object}
 * @property {string} friendlyDate
 * @property {number} daysInFuture
 * @property {number} hoursInFuture
 * @property {string} howFarInFuture
 *
 * @returns {friendlyDateReturnObj}
 */
export function getFriendlyDates({
  unixTime,
  userTimezone,
  format = "MMMM Do YYYY, h:mma z"
}) {
  const secondsFromNow = Math.round(unixTime - Date.now() / 1000);
  const daysInFuture = Math.round(secondsFromNow / 60 / 60 / 24);
  const hoursInFuture = Math.round(secondsFromNow / 60 / 60);
  const howFarInFuture = daysInFuture
    ? `${daysInFuture} ${daysInFuture === 1 ? "day" : "days"}`
    : `${hoursInFuture} ${hoursInFuture === 1 ? "hour" : "hours"}`;
  userTimezone = userTimezone || "GMT";
  const friendlyDate = moment(unixTime * 1000)
    .tz(userTimezone)
    .format(format);
  return {
    friendlyDate,
    daysInFuture,
    hoursInFuture,
    howFarInFuture
  };
}

/**
 * Take a timestamp and show in the specified momentjs compatible date format
 * This shows a complete, long date string. See also conversationalDate()
 * @param {int} params.timestamp - Unix timestamp of the date
 * @param {string} params.momentDateFormat - Moment.js compatible date-formatting string
 * @param {string} params.timezone - User timezone (ex: "America/Los_Angeles")
 */
export function userDate(params) {
  if (!params.timestamp)
    return "Invalid time in userDate " + JSON.stringify(params);
  params.momentDateFormat =
    params.momentDateFormat || "ddd, D MMM 'YY [at] h:mma z";
  params.timezone = params.timezone || moment.tz.guess();

  let userDate = moment(params.timestamp * 1000)
    .tz(params.timezone)
    .format(params.momentDateFormat);

  return userDate;
}

/**
 * Wrapper for userDate with same signature. Returns a conversational
 * date relative to how far it is in the future.
 * @param {int} params.timestamp - Unix timestamp of the date
 * @param {string} params.timezone - User timezone (ex: "America/Los_Angeles")
 */
export function conversationalDate(params) {
  if (params.momentDateFormat) {
    console.warn(
      "conversationalDate overrides date formats. Use userDate instead"
    );
  }

  const now = window.Sugar.Date.create("now").getTime() / 1000;
  let endOfToday =
    window.Sugar.Date.create("11:59:59pm tonight").getTime() / 1000;
  let endOfWeek =
    window.Sugar.Date.create("next Sunday 11:59:59pm").getTime() / 1000;
  let endOfYear =
    window.Sugar.Date.create("December 31st 11:59:59pm").getTime() / 1000;

  if (params.timestamp < now) {
    params.momentDateFormat = "D MMM 'YY"; // past due, caused by errors usually
  } else if (params.timestamp < endOfToday) {
    params.momentDateFormat = "h:mma";
  } else if (params.timestamp < endOfWeek) {
    params.momentDateFormat = "ddd h:mma";
  } else if (params.timestamp < endOfYear) {
    params.momentDateFormat = "ddd, D MMM";
  } else {
    params.momentDateFormat = "D MMM 'YY";
  }
  return userDate(params);
}

/**
 * Get a Unix timestamp from a natural date.
 * @param {string} naturalDate - User string
 * @return {number} - Unix timestamp of the date
 * @see https://sugarjs.com/dates/#/Parsing
 */
export function naturalTs(naturalDate, opts) {
  let date = window.Sugar.Date.create(naturalDate);
  if (opts && opts.advance) {
    const { advance } = opts;
    const { days } = advance;
    date = window.Sugar.Date.advance(date, { days });
  }
  return Math.floor(date.getTime() / 1000);
}

/**
 * Get logged-in user's access token
 * https://github.com/rsweetland/followupthen/issues/2142
 */

export function getAccessToken() {
  return cookie.get("mailbotsToken");
}

/**
 * Set logged-in user's access token
 * Renew expiration time on each new login
 * https://github.com/rsweetland/followupthen/issues/2142
 */
export function setAccessToken(token) {
  var expires = new Date();
  expires.setDate(expires.getDate() + 14);
  return cookie.set("mailbotsToken", token, {
    expires,
    secure: secureCookies
  });
}

// getAuthAcctsCookie(); // json parse + return
// existingAuthAccounts = JSON.stringify([{ emails: ['fdas@da.com', 'fdsa!rda.com', token: "abc123"]}]);
// Limit to three authorized accounts ()
export function addAuthorizedAccount({ token, emails, existingAccounts } = {}) {
  if (!existingAccounts) existingAccounts = [];
  const existingIndex = _.findIndex(
    existingAccounts,
    account => account && account.emails.some(e => emails.includes(e))
  );
  const accountExists = existingIndex !== -1;
  if (accountExists) {
    delete existingAccounts[existingIndex];
  }
  // only store 5 accounts for security. FILO.
  if (existingAccounts.length >= 5) existingAccounts.shift();
  existingAccounts.push({ token, emails });
  return existingAccounts;
}

// persiste auth accounts as a cookie
export function setAuthAccouns(authAccounts) {
  cookie.set("authAccounts", authAccounts, { secure: secureCookies });
}

/**
 * Prune extra accounts authorizedAccounts array. These get orphaned,
 * for example, when an email address is linked.
 */
export function pruneAuthorizedAccounts({ alreadyLinkedEmails }) {
  const allAuthorizedAccounts = getAuthorizedAccounts();
  if (!allAuthorizedAccounts) return;
  const prunedAuthorizedAccounts = allAuthorizedAccounts.filter(
    account => account && !alreadyLinkedEmails.includes(account.email)
  );
  cookie.set("authAccounts", JSON.stringify(prunedAuthorizedAccounts), {
    secure: { secure: secureCookies }
  });
}

/**
 * Get all of a user's authorized accounts, caching as needed
 * Ex: [{ emails: ['fdas@fdsa.com', 'fdsafdsa@fdsa.com'], authToken: "fdsafds"}]
 */
export function getAuthorizedAccounts() {
  try {
    const authAccountsCookie = cookie.get("authAccounts") || "[]";
    const authAccounts = JSON.parse(authAccountsCookie);
    if (!authAccounts.length) return null;
    return authAccounts;
  } catch (e) {
    console.error("Error parsing json");
    return false;
  }
}

export function getAuthAccountToken(email, authAccounts) {
  if (!authAccounts) return false;
  const matchingAccount = authAccounts.find(
    a => a && a.emails && a.emails.includes(email)
  );
  if (!matchingAccount) return false;
  return matchingAccount.token;
}

/**
 * Get an authoirzed account from email.
 * @returns {object|undefined}
 * @param {string} email
 */
export function getAuthorizedAccountFromEmail(email) {
  return (
    getAuthorizedAccounts() &&
    getAuthorizedAccounts().find(a => a.email === email)
  );
}

/**
 * See notes in App.js regarding ?gfr email address ("Gopher From").
 * We store login state for multiple addresses and link where the user comes
 * from each time.
 */
export function hasDuplicateAccounts() {
  if (window.location.href.startsWith("/settings")) return;
  const accounts =
    getAuthorizedAccounts() && getAuthorizedAccounts().filter(a => a); // filter empties
  if (accounts && accounts.length > 1) return true;
  return false;
}

export function isIgnoringDuplicateAccounts() {
  return !!cookie.get("ignoreDupAccounts");
}

// stop showing baner for duplicate accounts
export function ignoreDuplicateAccounts() {
  var expires = new Date();
  expires.setDate(expires.getDate() + 180);
  cookie.set("ignoreDupAccounts", "true", { expires });
}

/**
 * Logout user and refresh page
 */
export function logout() {
  cookie.remove("mailbotsToken");
  cookie.remove("authAccounts");
  cookie.remove("ignoreDupAccounts");
  logger.log("user logged out");
  window.location.reload();
}

// Check if a given email belongs to a mailbot command
// @todo consolidate ewith fut-mailbot lib
export function isMailBotsEmail(emailString) {
  return (
    emailString.search(
      /mailbots|memorize|followupthen\.com|fut\.io|staging1|eml\.bot/
    ) !== -1
  );
}

// @todo: This is a total hack. Minimally it should be cross-referneced with the "trusted" flag so
// not anyone can add this phrase to show "activated". That being said, errors here would only be UI-level
export function mailbotIsInstalled(mailbot) {
  if (!mailbot) return false;
  return mailbot.installed || isCoreSkill(mailbot);
}

// Core skills are internal to fut-mailbot and have their base URLs pointing there to properly fire webhooks, etc.
export function isCoreSkill(mailbot) {
  console.log("REACT_APP_FUT_MAILBOT_BASE_URL", process.env.REACT_APP_FUT_MAILBOT_BASE_URL);
  console.log("base_url", mailbot.base_url);
  console.log("startsWith", mailbot.base_url.startsWith(process.env.REACT_APP_FUT_MAILBOT_BASE_URL));
  return (
    mailbot.base_url.startsWith(process.env.REACT_APP_FUT_MAILBOT_BASE_URL) ||
    (mailbot.description &&
      mailbot.description.includes(
        "This skill comes pre-activated on all FollowUpThen accounts."
      ))
  );
}

// Turn a mailbot into a fut skill
export function convertMailBotToSkill(mailbot) {
  // ISKillInfo (more or less)
  let skillifiedMailBot = {
    name: mailbot.name,
    mailbotid: mailbot.id,
    subdomain: mailbot.subdomain,
    flag: mailbot.subdomain.replace(/-/gi, ""),
    action_namespace: mailbot.subdomain.replace(/-/gi, ""),
    data_namespace: mailbot.subdomain,
    search_key: `sk_${mailbot.subdomain}`,
    remote: true,
    show_as_search_filter: true, // Search futs by this skill?
    show_as_scheduling_option: true // Show while scheduling?
  };
  return skillifiedMailBot;
}

// Is the timestamp older than 5 minutes
export function incompleteAndPastDue(task) {
  if (task.completed) return false;
  const dueUnixTime = task.trigger_time;
  if (typeof dueUnixTime !== "number") return false;
  return dueUnixTime + 300 < Math.floor(Date.now() / 1000);
}

export function taskInErrorState(task) {
  if (incompleteAndPastDue(task)) return true;
  if (
    task &&
    task.search_keys &&
    task.search_keys.length &&
    task.search_keys.some(k => k == "sys_error")
  )
    return true;
}

/**
 * Accepts the complete API response and handles
 * different states of the mailbot webhook status
 */
export function handleWebhookResponseStatus(updateTaskApiResponse) {
  const webhookStatus = _.get(updateTaskApiResponse, "webhook.status");
  const webhookStatusMessage = _.get(updateTaskApiResponse, "webhook.message");

  if (
    webhookStatusMessage &&
    ["error", "warning", "success", "info"].includes(webhookStatus)
  ) {
    if (webhookStatus === "error")
      console.error("Webhook error response" + webhookStatusMessage);
    antMessage[webhookStatus](webhookStatusMessage); // ex: antMessage.warning('foo')
  }
}

/*
 * If a taskLog show skill warnings, return true
 * @param webhookApiResponse full API response
 * @return {boolean}
 */
export function hasSkillErrors(webhookApiResponse) {
  let skillsLog =
    webhookApiResponse &&
    webhookApiResponse.webhook &&
    webhookApiResponse.webhook.passthrough_data &&
    webhookApiResponse.webhook.passthrough_data.skillsLog;
  if (!skillsLog) {
    // if there is a time format error, fut-mailbot wont't dispatch skills. So, no skillsLog
    skillsLog = [{ status: "success" }];
  }
  const hasSkillLogErr = skillsLog.some(sk => sk.status !== "success");

  return hasSkillLogErr;
}

/**
 * Erros can also occurr with the webhook to fut-mailbot. For example:
 *  - an unrecognized date format could be used
 *  - webhook could timeout
 *  - fut-mailbot may have a bug
 * @param {*} webhookApiResponse
 * @returns {boolean}
 */
export function hasWebhookErrors(webhookApiResponse) {
  const webhookStatus =
    webhookApiResponse.webhook && webhookApiResponse.webhook.status;
  const hasWebhookErr = ["error", "warn", "fail"].includes(webhookStatus);
  return hasWebhookErr;
}

/**
 * Replace the old task command with the new one, keeping the same to/cc/bcc field. This assumes there is
 * only one email command per reference_email.
 * @param {object} task Task
 */
export function updateTaskAndReferenceEmailWithNewCommand(task, newCommand) {
  let newTask = _.cloneDeep(task);
  newTask.command = newCommand;

  // if it's a MailBots address, swap it out with the new one
  function swapMailBotsAddressWithTaskCommand(emailAddress) {
    if (!emailAddress) return;
    if (!isMailBotsEmail(emailAddress.toString())) return emailAddress;
    return newTask.command;
  }

  if (!Array.isArray(newTask.reference_email.to))
    newTask.reference_email.to = [];
  newTask.reference_email.to = newTask.reference_email.to.map(
    swapMailBotsAddressWithTaskCommand
  );
  if (!Array.isArray(newTask.reference_email.cc))
    newTask.reference_email.cc = [];
  newTask.reference_email.cc = newTask.reference_email.cc.map(
    swapMailBotsAddressWithTaskCommand
  );
  if (!Array.isArray(newTask.reference_email.bcc))
    newTask.reference_email.bcc = [];
  newTask.reference_email.bcc = newTask.reference_email.bcc.map(
    swapMailBotsAddressWithTaskCommand
  );

  // edge case where no command is on the task yet, for example, on the People page
  if (
    ![
      ...newTask.reference_email.to,
      ...newTask.reference_email.cc,
      ...newTask.reference_email.bcc
    ].includes(newTask.command)
  ) {
    newTask.reference_email.to.push(newTask.command);
  }

  return newTask;
}

/**
 * Check if the reference email is going to external recipients: (FUT in 'to' or 'cc' fields)
 * Note: this assumes the constraint enforced within fut-mailbot the FUT address needing to be in 'cc' field
 * (or 'to' field at some point) to be sent to an external recipient. This may not always be the case.
 * @param {object} referenceEmail  -- ex: {to: ["foo@email.com"], cc: [], bcc: [], subject: '', html: ''}
 * @param {array} userEmails  -- ex: ["email@email.com", "email2@email.com"]
 */
export function referenceEmailHasExtRecipients(referenceEmail, userEmails) {
  const toArr = referenceEmail.to || [];
  const ccArr = referenceEmail.cc || [];
  const userOwnsEmail = fullEmail => {
    if (!fullEmail) return true;
    return userEmails.some(ue => fullEmail.includes(ue.email));
  };
  const hasExternalRecipients =
    !toArr.every(userOwnsEmail) || !ccArr.every(userOwnsEmail);
  return hasExternalRecipients;
}

/**
 * Check if an email address is from a FUT domain.
 */
export function isFutAddress(email) {
  if (!email) return false;
  const futEmails = ["@fut.io", "@followupthen.com", "@staging1.fut.io"];
  return futEmails.some(e => email.includes(e));
}

/**
 * Check if an email address belongs to the account owner
 */
export function isOwnerAddress(emailToCheck, loggedInUser) {
  if (!loggedInUser || !Array.isArray(loggedInUser.emails)) return false;
  return loggedInUser.emails.some(e => e.includes(emailToCheck));
}

/**
 * task.reference_email is the single source source of truth for recipient types.
 * If it's a 'cc', anyone in the 'to' field is an external recipient. FUT addresses excluded.
 *
 * This does not take into account People resources.
 *
 *
 * @param {object} referenceEmail
 * @returns {object} object.extRecipients, nonRecipients, ownerEmail
 */
export function getRecipientTypes(referenceEmail, method = "bcc") {
  if (!referenceEmail) return {};

  const ownerEmail = referenceEmail.from; // owner address
  let extRecipients = [];
  let nonRecipients = [];
  const toRecipients = referenceEmail.to || [];

  const excludeFutAddresses = email => !isFutAddress(email);

  if (method === "cc") {
    extRecipients = extRecipients
      .concat(referenceEmail.to)
      .filter(excludeFutAddresses);
  }

  nonRecipients = toRecipients
    .filter(recipient => !extRecipients.includes(recipient))
    .filter(excludeFutAddresses);

  return { nonRecipients, extRecipients, ownerEmail };
}

export function taskCommandNotInRefEmail(task) {
  if (!task.reference_email) return false;
  const allRecipients = [
    ...task.reference_email.to,
    ...task.reference_email.cc,
    ...task.reference_email.bcc
  ];
  return allRecipients.some(
    recipient =>
      recipient.includes("followupthen.com") || recipient.includes("fut.io")
  );
}

export function hasOnlyOneFutFormat(task) {
  if (!task.reference_email) return false;
  const allRecipients = [
    ...task.reference_email.to,
    ...task.reference_email.cc,
    ...task.reference_email.bcc
  ];
  let futCount = 0;
  allRecipients.forEach(recipient => {
    if (recipient.includes("followupthen.com") || recipient.includes("fut.io"))
      ++futCount;
  });
  if (futCount !== 1) return false;
}

/**
 * @todo DRY with this: https://github.com/mailbots/mailbots/blob/1e5c8c4a1d99e4da6189d45e7e4e3e2229c7966c/src/lib/webhook-helpers.ts#L276-L312
 * Determine if the email command for the current task was placed in the 'to', 'cc' or 'bcc'
 * fields. This is done by comparing the email command with the email address in the  and 'to', 'cc'
 * fields. The 'bcc' is hidden from the message envelope but still present in the command.
 *
 * Note that if the identical email command is in both 'to', 'cc' and 'bcc' it will
 * only show up as the 'to' method.
 */

export function getEmailMethod(referenceEmail, command) {
  if (!referenceEmail || !command) return;
  const thisCommand = command.toLowerCase();

  // edge case that applies only to FollowUpThen, where there are two domains that point to the same mailbot
  const thisAltCommand = thisCommand.replace("followupthen.com", "fut.io");

  // defensive
  function addressFieldToArray(field) {
    if (field instanceof Array) return field;
    if (typeof field === "string") return field.split(",");
    else return [];
  }

  let to = referenceEmail.to || [];
  to = addressFieldToArray(to);
  to = to.map(to => to.toLowerCase());

  let cc = referenceEmail.cc || [];
  cc = addressFieldToArray(cc);
  cc = cc.map(cc => cc.toLowerCase());

  if (to.includes(thisCommand) || to.includes(thisAltCommand)) {
    return "to";
  } else if (cc.includes(thisCommand) || cc.includes(thisAltCommand)) {
    return "cc";
  } else {
    return "bcc";
  }
}

// internal
export const removeAllFutCommands = email => {
  let newEmail = { ...email };
  // remove it from all fields and add it to only cc field (so it's unique)
  newEmail.to = email.to.filter(ccEmail => !isMailBotsEmail(ccEmail));
  newEmail.cc = email.cc.filter(ccEmail => !isMailBotsEmail(ccEmail));
  newEmail.bcc = email.bcc.filter(ccEmail => !isMailBotsEmail(ccEmail));
  return newEmail;
};

export const findFutCommandInEmail = email => {
  const allEmails = [...email.to, ...email.cc, ...email.bcc];
  const futCommand = allEmails.filter(eml => isMailBotsEmail(eml));
  if (futCommand.length > 1) {
    logger.log("move than one fut address in email", { level: "error" });
    throw Error("Task has more than one command");
  }
  return futCommand[0];
};

export const moveFutCommand = (eml, targetField, forceCommand = "") => {
  if (!["to", "cc", "bcc"].includes(targetField))
    throw Error("targetField must be to|cc|bcc");
  let newEmail = Object.assign({}, eml);
  let futCommand = forceCommand || findFutCommandInEmail(eml);
  if (!futCommand) {
    logger.log("no fut command found in reference email", { level: "warn" });
    return newEmail;
  }
  newEmail = removeAllFutCommands(newEmail);
  newEmail[targetField] = newEmail[targetField].concat(futCommand);
  return newEmail;
};

// when using our UI, make the reference email like it would normally be
export const normalizeEmail = (email, futCommand = "") => {
  if (!email) throw Error("Something went wrong");
  if (!futCommand) {
    futCommand = findFutCommandInEmail(email);
  }
  if (!futCommand) {
    throw Error("followup command required to normalize email");
  }

  let newEmail = { ...email };

  // remove empty strings
  newEmail.to = newEmail.to.filter(eml => !!eml);
  newEmail.cc = newEmail.cc.filter(eml => !!eml);
  newEmail.bcc = newEmail.bcc.filter(eml => !!eml);

  // de-duplicate addresses
  newEmail.to = _.uniq(newEmail.to);
  newEmail.cc = _.uniq(newEmail.cc);
  newEmail.bcc = _.uniq(newEmail.bcc);

  // remove whitespace
  newEmail.to = newEmail.to.map(eml => eml.trim());
  newEmail.cc = newEmail.cc.map(eml => eml.trim());
  newEmail.bcc = newEmail.bcc.map(eml => eml.trim());

  // no blank 'to' address (if it is blank, it doesn't matter if fut is in cc/bcc - it would never act as a cc fut without recipients)
  if (!newEmail.to || !newEmail.to.length) {
    newEmail = moveFutCommand(newEmail, "to", futCommand);
  }

  // if FUT is not where in the email, force 'bcc'
  if (!findFutCommandInEmail(newEmail)) {
    newEmail = moveFutCommand(newEmail, "bcc", futCommand);
  }

  // move fut from 'to' to 'bcc' when its included in a 'to' recipient list (only to make normal-looking, not functional)
  if (
    (getEmailMethod(newEmail, futCommand) === "to" && !newEmail.to) ||
    newEmail.to.length > 1
  ) {
    newEmail = moveFutCommand(newEmail, "bcc");
  }

  return newEmail;
};

/** Parse date format, add skill to command
 * @param {string} existingCmd - ex: 3days-sms-r+foo
 * @param {string} newSkill - ex: -t.3days
 *  @todo: make format parser into common fn. DRY https://github.com/mailbots/fut-mailbot/blob/e9ddf48d6affbacdcec6a333a9ba8bea631cc460/src/lib/dateUtil.ts#L43
 */
export function addSkillToCommand(existingCmd, newSkill) {
  // let [justTime, everythingElse] = existingCmd.split(/-(.+)/); // Ex: 3days-sms.1+foo+bar -> ["3days", "-sms.1+foo+bar"]
  // return justTime + newSkill + (everythingElse ? "-" + everythingElse : "");
  const { dateStr, skillsArr, domain } = futCmdParse(existingCmd);
  skillsArr.push(newSkill);
  return futCmdStringify({ dateStr, skillsArr, domain });
}

export function removeSkillFromCommand(existingCmd, skillToRemove) {
  let { dateStr, skillsArr, domain } = futCmdParse(existingCmd);
  skillsArr = skillsArr.filter(s => !s.includes(skillToRemove));
  return futCmdStringify({ dateStr, skillsArr, domain });
}

/**
 * Simple parser for fut command, separating date format and skills and domain
 */
export function futCmdParse(cmdStr) {
  if (!cmdStr) return {};
  let localPart;
  let domain;
  if (cmdStr.includes("@")) {
    [localPart, domain] = cmdStr.split("@");
  } else {
    localPart = cmdStr;
  }
  const [dateStr, ...skillsArr] = localPart.split("-"); // Ex: 3days-sms.1+foo+bar -> ["3days", "-sms.1+foo+bar"]
  return { dateStr, skillsArr, domain };
}

/**
 * Simple stringifier for fut command
 */
export function futCmdStringify({ dateStr, skillsArr, domain = null }) {
  const skillsStr = skillsArr.length ? "-" + skillsArr.join("-") : "";
  const localPart = dateStr + skillsStr;
  return localPart + (domain ? `@${domain}` : "");
}

/**
 * Login the person, but pre-fill their user name, show a custom message
 * and send them on their way after they login. Note – You can also set a "mailbotsRedirect"
 * cookie to account redirecting after more complex login flows, like email-based.
 * This url-based redirect takes precidence over (and erases) the mailbotsRedirect cookie
 */
export function smartLogin({
  email = undefined,
  redirect = undefined,
  message = undefined
}) {
  // collect data to debug unnecessary logins
  const authorizedAccounts = getAuthorizedAccounts() || [];
  const auth_accounts_str = authorizedAccounts
    .map(a => a && a.email)
    .join(", ");
  const has_auth_token = !!getAccessToken();

  logger.log("smart login with redirect", {
    data: {
      redirect_str: redirect,
      email_str: email,
      message_str: message,
      auth_accounts_str,
      has_auth_token
    }
  });
  // prevent redirecting back to self
  if (redirect && !redirect.includes("/login"))
    cookie.set("mailbotsRedirect", redirect);
  // login container also supports passing ?redirect, but it erases the cookie
  // @todo - possibly kill this feature in login container
  window.location.href = `/login?${queryString.stringify(
    { email, message },
    { skipNull: true }
  )}`;
}

export const isValidEmail = email => {
  const isValid =
    email.search(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    ) > -1;
  return isValid;
};

// stripe window.location.search without reloading page
export const stripAllQueryParams = () => {
  window.history.pushState({}, "", window.location.pathname);
};

export const stripQueryParam = param => {
  const qs = queryString.parse(window.location.search);
  if (qs[param]) delete qs[param];
  const path = window.location.pathname;
  window.history.pushState({}, "", path + "?" + queryString.stringify(qs));
};

export const isOnV3 = loggedInUser =>
  loggedInUser &&
  loggedInUser.opt_into_fut_mailbot &&
  loggedInUser.migrated_to_v3;

export const getAffiliateLink = loggedInUser =>
  `${process.env.REACT_APP_MAILBOTS_WWW_BASE}/?a=${getReferralHash(
    loggedInUser
  )}`;
/**
 * Commonly used FUT formats for autocompletes and things
 * @returns {label: format, value: format}
 */
export const getAutocompleteOptions = (prioritizedPostponeTimes = []) => {
  const formatGenerator = new FormatGenerator();
  let formats = formatGenerator.getDefaultFormats();
  const recentlyUsedFormats = getRecentlyUsedFormats();
  formats.unshift(...recentlyUsedFormats);
  return formats;
};

/**
 * Save and retrieve user's commonly used FUT formats. Just a cookie for now, eventually awesome
 */
const recentFormatCookieName = "recentFutFormats";
export function setRecentlyUsedFormat(newFormat) {
  let recentlyUsedFormats = getRecentlyUsedFormats();
  if (!Array.isArray(recentlyUsedFormats)) {
    console.error("recently used formats should be an array");
    return;
  }

  recentlyUsedFormats.unshift(newFormat); // push at the top
  const recentlyUsedFormatsDeDuplicated = [...new Set(recentlyUsedFormats)]; // make unique
  cookie.set(
    recentFormatCookieName,
    JSON.stringify(recentlyUsedFormatsDeDuplicated)
  );
}

export function getRecentlyUsedFormats() {
  const recentlyUsedFormatsCookie = cookie.get(recentFormatCookieName);
  if (recentlyUsedFormatsCookie === undefined) return [];
  let recentlyUsedFormats = [];
  try {
    recentlyUsedFormats = JSON.parse(recentlyUsedFormatsCookie);
  } catch (e) {
    console.error("Error parsing recently used formats");
    debugger;
  }
  return recentlyUsedFormats;
}

export function getSkillsAppliedToTask(task, availableSkills) {
  if (!availableSkills || !task || !task.search_keys) return [];
  const appliedFlags = task.search_keys
    .filter(k => k.startsWith("sk_"))
    .map(k => k.replace("sk_", ""));
  return availableSkills.filter(sk => appliedFlags.includes(sk.subdomain));
}

/**
 * Parse a full fut command and extract individual components (timeFormat, skills, etc).
 * @todo de-duplicate. fut-mailbot has a copy of this here: https://github.com/mailbots/fut-mailbot/blob/158458ef71f5d992e95484f75cc091be8929b649/src/lib/commandHelpers.ts#L11
 */
export function parseFutCommand(command, existingSkills = []) {
  let timeFormat = "";
  let tags;
  const SKILL_ARGUMENT_SEPARATOR = "-";
  const SKILL_TAG_SEPARATOR = "+";

  const fullEmailParts = command.split("@");
  let commandFormat = fullEmailParts[0];
  const emailDomain = fullEmailParts[1];

  // split the command format by hyphens
  let parts = commandFormat.split(SKILL_ARGUMENT_SEPARATOR);

  // check if this is a timeless skill (first part is a skill)
  // https://github.com/mailbots/fut-mailbot/issues/144
  const isTimeless = existingSkills
    .filter(sk => sk.timeless)
    .some(
      sk =>
        parts[0] === (sk.flag || "") ||
        parts[0].startsWith(`${sk.flag || ""}${SKILL_ARGUMENT_SEPARATOR}`)
    );

  // Ex: 2020-09-16
  const is8601Format = parts =>
    Array.isArray(parts) &&
    parts.length >= 3 &&
    /^[0-9]{4}$/i.test(parts[0]) &&
    /^[0-9]{2}$/i.test(parts[1]) &&
    /^[0-9]{2}$/i.test(parts[2]);

  if (!isTimeless) {
    if (is8601Format(parts)) {
      // transform date format parts back into 8601 string: Ex: 2020-09-11
      timeFormat = [parts.shift(), parts.shift(), parts.shift()].join("-");
    } else {
      // in non-ISO8601, the format the fist part is the time format. Ex: tues-sms-en
      // remainig "parts" are assumed to contain only skills (["-sms", "-en"])..same as above
      timeFormat = parts.shift() || "";
    }

    // extract general tags from format (ex. 3days+tag+tag2)
    const tagParts = timeFormat.split(SKILL_TAG_SEPARATOR);
    timeFormat = tagParts.shift() ?? timeFormat;
    tags = tagParts;
  }

  const skills = parts.map(skillFormat => {
    const skillParts = skillFormat.split(SKILL_ARGUMENT_SEPARATOR);

    return {
      flag: skillParts[0],
      args: skillParts.slice(1)
    };
  });

  return {
    isTimeless,
    timeFormat, // ex 3days
    emailDomain, // @fut.io
    tags,
    skills
  };
}

// sso account creation can give validated email without terms accepted
const userNotYetValidated = loggedInUser =>
  loggedInUser && (!loggedInUser.is_validated || !loggedInUser.accepted_terms);

// get proper welcome tour based on user state
export const getTourForUser = (loggedInUser, qs = {}) => {
  let dialog;
  let tourKey;
  let stepId;
  if (false) {
    // make easier to reorder below...
  } else if (userNotYetValidated(loggedInUser) && qs.welcome === "new-user") {
    dialog = "new-user";
  } else if (qs.welcome === "new-user") {
    dialog = "new-user";
  } else if (qs.welcome) {
    dialog = "new-user";
  } else if (qs.tour) {
    dialog = "new-user";
  } else if (qs.upgradedPlan && loggedInUser && !isOnV3(loggedInUser)) {
    stepId = "upgrade";
    dialog = "new-user";
  } else if (loggedInUser && !isOnV3(loggedInUser)) {
    dialog = "new-user";
  } else if (userNotYetValidated(loggedInUser)) {
    dialog = "new-user";
    stepId = "welcome-new-user";
  } else {
    dialog = false;
  }

  if (!loggedInUser || typeof loggedInUser !== "object") {
    // force step + tour by prop
  } else if (!loggedInUser.accepted_terms && isEmployee(loggedInUser)) {
    logger.log("v3 tour: new employee");
    tourKey = "newEmployee";
    stepId = undefined; // start at beginning
  } else if (!loggedInUser.accepted_terms && isEmployee(loggedInUser)) {
    logger.log("v3 tour: employee hasn't accepted terms");
    tourKey = "existingEmployee";
    stepId = "welcome-new-user"; // start at beginning
  } else if (!loggedInUser.accepted_terms && isTeamOwner(loggedInUser)) {
    logger.log("v3 tour: team owner hasn't accepted terms");
    tourKey = "existingTeamOwner";
    stepId = "welcome-new-user"; // start at beginning
  } else if (loggedInUser.accepted_terms && !loggedInUser.is_validated) {
    // user stuck mid email-validation
    tourKey = "newUser";
    stepId = "validate-email";
    logger.log("v3 tour: new user needs to validate");
  } else if (isTeamOwner(loggedInUser) && hasEmployees(loggedInUser)) {
    tourKey = "existingTeamOwner";
    stepId = "welcome-existing-user";
    logger.log("v3 tour: existing team owner");
  } else if (!loggedInUser.accepted_terms || !loggedInUser.is_validated) {
    // new user email could be validated from SSO login
    tourKey = "newUser";
    logger.log("v3 tour: new user");
  } else if (loggedInUser.accepted_terms && isEmployee(loggedInUser)) {
    tourKey = "existingEmployee";
    logger.log("v3 tour: existing employee");
  } else if (loggedInUser.accepted_terms) {
    tourKey = "existingIndividualUser";
    stepId = undefined; // start at beginning
    logger.log("v3 tour: existing user");
  } else {
    logger.log("v3 tour: unexpected user state", { level: "warn" });
    tourKey = "newUser";
  }

  // allow URL to override tour steps, for sending people to exact spots
  if (qs.tourKey) tourKey = qs.tourKey;
  if (qs.stepId) stepId = qs.stepId;

  return { dialog, tourKey, stepId };
};

// for responsive css for legacy code. Emotion / Styled Components is preferred
export const isOnMobile = () => window.innerWidth < 768;

// sent analytics event with Google Tag Manager (included in index.js)
export const sendGtmEvent = (eventName, eventDetails) => {
  if (typeof window !== "undefined" && window.dataLayer) {
    window.dataLayer.push({
      event: eventName,
      ...eventDetails
    });
  } else {
    logger.log("sendGtmEvent: no window.dataLayer", { level: "warn" });
  }
};
